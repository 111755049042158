/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
/*
* @group Forms
* @variable $button-background-color The button background color
*/
@import '../core/core';
@import '../typography/_variables';
@import '../../mixins/_form';
@import './_variables';

::placeholder {
  color: @form-placeholder-color;
}

form {
  position: relative;
  max-width: 100%;

  input[type="search"] {
    box-sizing: border-box;
  }

  textarea,
  input[type="search"],
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="password"],
  input[type="number"],
  input[type="url"],
  input[type="tel"] {
    width: @input-width;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    padding: @input-padding;
    border: @input-border;
    border-radius: @input-border-radius;
    &:focus {
      outline: 0;
      box-shadow: @input-focus-box-shadow;
      border-color: @input-focus-border-color;
      background: @input-focus-background;
    }

    // Disabled and read-only inputs
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &[disabled],
    &[readonly],
    fieldset[disabled] & {
      cursor: not-allowed;
      background-color: @input-disabled-background-color;
      .opacity(100);
    }
  }

  textarea:focus {
    color: @textarea-focus-color;
    border-radius: @textarea-focus-border-radius;
    background: @textarea-focus-background;
    box-shadow: @textarea-focus-box-shadow;
    border-color: @textarea-focus-border-color;
  }

}

// Search inputs in iOS
// This overrides the extra rounded corners on search inputs in iOS
// https://github.com/twbs/bootstrap/issues/11586.
textarea:focus,
input[type="search"] {
  -webkit-appearance: none;
}

form > p {
  margin: @typo-paragraph-margin;
}

input {

}

.form-actions {
  .clearfix();
  padding: @form-actions-padding;
  margin: @form-actions-margin;
  background-color: @form-actions-background;
  border: @form-actions-border;
}

.form-row {
  margin-bottom: @typo-line-height;
  .form-field-wrapper {
    position: relative;
  }
  .form-field-wrapper.inline {
    display: inline;
  }
}

label.form-error {
  background: @form-error-label-background;
  display: inline-block;
  padding: @input-padding;
  font-size: @typo-font-size;
  font-weight: bold;
  color: @form-error-label-color;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: @border-radius;
  &:hover {
    background-color: darken(@form-error-label-background, 10%);
  }
}

// required star
span.required {
  font-size: 120%;
  color: @form-required-star-color;
}
