.ui-dialog {
  box-shadow: @dialog-shadow;
  background-clip: padding-box;
  background: @dialog-background;
  border-radius: @dialog-border-radius;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  outline: 0;
  z-index: @dialog-z-index;
  .ui-dialog-titlebar {
    padding: @dialog-titlebar-padding;
    position: relative;
    background: @dialog-titlebar-background;
    border-bottom: @dialog-titlebar-border;
  }

  &.no-titlebar .ui-dialog-titlebar {
    display: none;
  }

  .ui-dialog-title {
    display: block;
    font-size: @dialog-title-font-size;
    padding: @dialog-title-padding;
  }

  &.no-title .ui-dialog-title {
    display: none;
  }

  &.no-title .ui-dialog-titlebar {
    border: 0;
  }
}

.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: -1em;
  top: -1em;
  z-index: @dialog-z-index;
}

.ui-dialog.compact .ui-dialog-content {
  padding: 0;
  overflow: visible;
}

.ui-dialog .ui-dialog-content {
  position: relative;
  border: 0;
  padding: 0.5em 1em;
  background: none;
}

.ui-dialog .ui-dialog-buttonpane {
  text-align: left;
  border-width: 1px 0 0 0;
  background-image: none;
  margin-top: .5em;
  padding: .3em 1em .5em .4em;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right;
  margin-top: 1em;
  button {
    margin-right: .5em;
  }
  button:last-child {
    margin-right: 0;
  }
  .ui-button-text {
    display: inline;
  }
}
