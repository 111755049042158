// Tables variables

@import "../core/_variables";
@import "../typography/_variables";

// basic
@table-background: inherit;
@table-margin: @typo-line-height 0;
@table-border: 0.1em solid #ddd;
@table-box-shadow: @box-shadow;
@table-border-radius: 0.3em;
@table-color: inherit;
@table-border-collapse: collapse;
@table-border-spacing: 0;

// header
@table-header-background: #ccc;
@table-header-align: center;
@table-header-color: #000;
@table-header-vertical-align: middle;
@table-header-vertical-padding: 0.5em;
@table-header-horizontal-padding: 0.5em;
@table-header-font-style: inherit;
@table-header-font-weight: bold;
@table-header-text-transform: uppercase;
@table-header-border: 0;
@table-header-divider: 0.1em solid #ccc;
@table-border-radius: 0.5em;

// footer
@table-footer-box-shadow: @box-shadow;
@table-footer-background: @table-header-background;

// rows
@table-row-border: 0.1em solid #ccc;

// cells
@table-cell-vertical-padding: 0.3em;
@table-cell-horizontal-padding: 0.3em;
@table-cell-vertical-align: middle;
