
@import '../core/_variables';

@veil-max-height: 135px; // the max height in pixels
@veil-toggle-color: #000;
@veil-toggle-align: right;
@veil-toggle-background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 40%, rgba(255, 255, 255, 1) 100%);

@veil-toggle-info-border-radius: @border-radius;
@veil-toggle-info-background: #aaa;
@veil-toggle-info-padding: 0.5em 0.7em;
@veil-toggle-info-margin: 0 0.1em 0.1em 0;
@veil-toggle-info-color: #fff;
@veil-toggle-info-opacity: 1;
