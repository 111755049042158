/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

@import '../../mixins/_sprites';
@import './_variables';

/*
* @group Tables
* @description Tables
*/

table {

}

/*
* @name .u-table
* @description Nice table
* @markup
<table class="u-table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Status</th>
      <th>Notes</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>John</td>
      <td>Approved</td>
      <td>None</td>
    </tr>
    <tr>
      <td>Jamie</td>
      <td>Approved</td>
      <td>Requires call</td>
    </tr>
    <tr>
      <td>Jill</td>
      <td>Denied</td>
      <td>None</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th colspan="3">
        Table footer
      </th>
    </tr>
  </tfoot>
</table>
*/
table.u-table {

  width: 100%;
  background: @table-background;
  margin: @table-margin;
  border: @table-border;
  box-shadow: @table-box-shadow;
  border-radius: @table-border-radius;
  color: @table-color;
  border-collapse: @table-border-collapse;
  border-spacing: @table-border-spacing;

  thead {
    th {
      cursor: auto;
      background: @table-header-background;
      text-align: @table-header-align;
      color: @table-header-color;
      padding: @table-header-vertical-padding @table-header-horizontal-padding;
      vertical-align: @table-header-vertical-align;
      font-style: @table-header-font-style;
      font-weight: @table-header-font-weight;
      text-transform: @table-header-text-transform;
      border-bottom: @table-header-border;
      border-left: @table-header-divider;

      &:first-child {
        border-radius: 0;
        border-left: none;
      }

      &:last-child {
        border-radius: 0;
      }
    }
  }

  tbody {
    td {
      padding: @table-cell-vertical-padding @table-cell-horizontal-padding;
      vertical-align: @table-cell-vertical-align;
    }
  }

  tr td {
    border-top: @table-row-border;
  }

  tr:first-child td {
    border-top: none;
  }

  tfoot {
    box-shadow: @table-footer-box-shadow;
    th, tr {
      background: @table-footer-background;
    }
  }

}
