/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*
 * @group Messages
 * @description The messages
 * @variable $message-padding The padding of the message container
 * @variable $message-margin The margin of the message container
 * @variable $message-border-radius The border radius
 * @variable $message-link-font-weight The font weight of link inside the message box
 * @variable $message-state-info-bg Info state background
 * @variable $message-state-info-text Info state text color
 * @variable $message-state-info-border Info state border color
 * @variable $message-state-danger-bg Danger state background
 * @variable $message-state-danger-text Danger state text color
 * @variable $message-state-danger-border Danger state border color
 * @variable $message-state-warning-bg Warning state background
 * @variable $message-state-warning-text Warning state text color
 * @variable $message-state-warning-border Warning state border color
 */
@import '../core/core';
@import '../../mixins/_messages';
@import './_variables';

/*
 * @name .message
 * @description The notification message
 * @state warning - Warning state
 * @state info - Info state
 * @state danger - Danger state
 * @state compact - Compact version (A message can only take up the width of its content)
 * @belongsTo Messages
 * @markup
  <div class="message danger" role="alert">
    <strong>Danger</strong> Your mailbox is full!
  </div>
 */
.u-message, // BC compat
.message {

  padding: @message-padding;
  margin: @message-margin;
  border: 0.2em solid transparent;
  border-left-width: 0.7em;
  border-radius: @message-border-radius;

  // Headings for larger alerts
  h4, .heading {
    margin-top: 0;
    display: block;
    // Specified for the h4 to prevent conflicts of changing @headings-color
    color: inherit;
  }

  // Provide class for links that match alerts
  a {
    font-weight: @message-link-font-weight;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }
  > p + p {
    margin-top: (@message-padding/2);
  }

  &.compact {
    display: inline-block;
  }

  &.warning {
    .message-variant(@message-state-warning-bg, @message-state-warning-border, @message-state-warning-text);
  }
  &.error,
  &.danger {
    .message-variant(@message-state-danger-bg, @message-state-danger-border, @message-state-danger-text);
  }
  &.info {
    .message-variant(@message-state-info-bg, @message-state-info-border, @message-state-info-text);
  }
  &.success {
    .message-variant(@message-state-success-bg, @message-state-success-border, @message-state-success-text);
  }

  // dismiss button
  button[data-dismiss] {
    float: right;
    margin-top: -(@message-padding/1.5);
    cursor: pointer;
    background: transparent;
    border: 0;
    text-shadow: none;
    box-shadow: none;
  }
}
