// --------------------------------------------------
// Core
// --------------------------------------------------
@import (reference) '../../mixins/_core';
@import (reference) './_variables';

.clearfix {
  .clearfix();
}

.visually-hidden {
  .visually-hidden();
}

.visually-hidden-focusable {
  .visually-hidden(true);
}
