@import './_variables';
@import '../../mixins/_animations';

.veil {
  overflow: hidden;
  max-height: @veil-max-height;
  position: relative;
  &.opened {
    overflow: visible;
    max-height: none;
  }
}

a.veil-toggle {
  height: auto;
  width: 100%;
  display: block;
  position: absolute;
  text-align: @veil-toggle-align;
  left: 0;
  bottom: 0;
  color: @veil-toggle-color;
  text-decoration: none;
  background: @veil-toggle-background;
  span {
    display: inline-block;
    padding: @veil-toggle-info-padding;
    font-weight: normal;
    margin: @veil-toggle-info-margin;
    border-radius: @veil-toggle-info-border-radius;
    background: @veil-toggle-info-background;
    color: @veil-toggle-info-color;
    opacity: @veil-toggle-info-opacity;
  }
  &:hover {
    > span {
      opacity: 1;
      transform: scale(0.8);
    }
  }
}
