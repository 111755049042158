// --------------------------------------------------
// Typography
// --------------------------------------------------
@import '../core/core';
@import '../../mixins/_typography';
@import './_variables';

// base text selection color
.selection(@typo-text-selection-color, @typo-text-selection-bg);

/*
* @group Typography
* @variable $typo-base-font-size - Base font size
* @variable $typo-line-height - Line height
*/
html {
  font-size: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: @typo-font-size;
  line-height: @typo-line-height;
  // @see http://aestheticallyloyal.com/public/optimize-legibility/
  text-rendering: optimizeLegibility;
  color: @typo-font-color;
}

abbr,
acronym,
dfn[title] {
  cursor: help;
}

abbr a {
  text-decoration: none;
}

acronym {
  text-transform: uppercase;
}

em,
i,
cite {
  font-style: italic;
}

nav ul,
nav ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Nested `list-style` types for ordered lists
ol {
  list-style: decimal;
}

ol ol {
  list-style: upper-alpha;
}

ol ol ol {
  list-style: lower-roman;
}

ol ol ol ol {
  list-style: lower-alpha;
}

ol ol,
ol ul,
ul ul,
ul ol {
  margin-bottom: 0;
}

.text-left {
  .text-left();
}

.text-right {
  .text-right();
}

.text-center {
  .text-center();
}

.text-justify {
  .text-justify();
}

hr {
  margin: 1em 0;
  border: 0;
  border-bottom: 0.1em solid @typo-hr-color;
}

p {
  line-height: @typo-line-height;
  margin-top: @typo-line-height;
  margin-bottom: @typo-line-height;
}

p + p {
  margin-top: 0;
}

h1 {
  margin: 2rem 0;
}

h2 {}
h3 {}
h4 {}

.small {}
.normal {}
.large {}
.huge {}
.massive {}
.gigantic {}
