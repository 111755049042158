// --------------------------------------------------
// Animations
// --------------------------------------------------

/**
 * @group Animations
 * @description CSS3 animations
 */
@import '../../mixins/_animations';
@import './_variables';
