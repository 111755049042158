/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

@import './_variables';
@import (reference) '../../mixins/_images';
@import '../animations/animations';

/*
 * @group Rating
 * @description The 5 star rating
 * @variable $rating
 */
div.rating {
  display: inline-block;
  .background-image(@rating-star-image);
  background-position: top left;
  background-repeat: repeat-x;
  overflow: hidden;
  // 5 stars * width of one star
  width: (5*@rating-star-width);
  height: @rating-star-height;
  position: relative;

  &:before {
    content: ' ';
    display: block;
    height: 100%;
    position: absolute;
    .background-image(@rating-star-image);
    background-position: bottom left;
    background-repeat: repeat-x;
  }

  &.small {
    .background-image(@rating-star-small-image);
    width: (5*@rating-star-small-width);
    height: @rating-star-small-height;
    &:before {
      .background-image(@rating-star-small-image);
    }
  }

  > span {
    display: none;
  }
}

h1 .rating,
h2 .rating {
  vertical-align: top;
}

// Generate percentage states for the
// 100 states, until the browsers supports
// width: calc(attr(data-rating-percentage) * 1%);
.rating-generate-percentages(@counter: 1) when (@counter <= 100) {
  @selector: ~'div.rating[data-rating-percentage="@{counter}"]';
  @{selector}:before {
    width: (@counter * 1%);
  }
  .rating-generate-percentages((@counter + 1)); // next iteration
}

.rating-generate-percentages();

// calc on data attributes
/*
div.rating[data-rating-percentage="0"]:before {
  width: 20%;
  // Use this when browsers support is ok for calc on data attribute:
  // width: calc(attr(data-rating-percentage) * 1%);
}
*/

ul.rating-stars-list {
  list-style-type: none;
  padding: 0.5em;
  margin: 1em 0;
  > li {
    padding: 0.5em 0;
  }

  li:hover .description {
    opacity: 1;
  }

  .description {
    margin-left: 1em;
    opacity: 0.4;
    .transition(opacity, 0.2s)
  }

}
