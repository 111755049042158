@import './_gradients';

// Button backgrounds
// ------------------
.button-background(@startColor, @endColor, @textColor: #fff, @textShadow: 0 -1px 0 rgba(0,0,0,.25)) {
  // gradientBar will set the background to a pleasing blend of these, to support IE<=9
  .gradient-bar(@startColor, @endColor, @textColor, @textShadow);
  // in these cases the gradient won't cover the background, so we override
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    color: @textColor;
    background-color: @endColor;
  }
}
