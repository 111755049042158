
// fonts location relative to css (or absolute)
@font-path: '../fonts';
@font-family: '';

@typo-font-color:               #222;
@typo-hr-color:                 #ccc;
@typo-font-size:                1rem;
@typo-line-height:              1.4em;
@typo-text-selection-color:     #fff; // text color
@typo-text-selection-bg:        #2f456a; // text background color

@typo-paragraph-margin:         0.5em 0;

// links
@link-color:                    #2f456a;
@link-padding:                  0.1em 0.2em;
@link-font-weight:              bold;
@link-text-decoration:          underline;
// hover
@link-hover-text-decoration:    none;
@link-hover-color:              #29556a;
// visited
@link-visited-text-decoration:  none;
@link-visited-color:            #23556a;
