/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

.placeholder(@form-placeholder-color: #ddd) {
  :-moz-placeholder {
    color: @form-placeholder-color;
  }
  ::-webkit-input-placeholder {
    color: @form-placeholder-color;
  }
}
