/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

// we use core variables
@import '../core/_variables';

@form-placeholder-color: #ddd;

// buttons
@button-text-color: #222;
@button-font-size: 1rem;
@button-background: #fff;
@button-padding: 0.4rem 0.9rem;
@button-border-color: #ccc;
@button-border: 0.1rem solid @button-border-color;
@button-background-highlight: darken(@button-background, 5%);
@button-active-background: darken(@button-background, 10%);
@button-active-box-shadow: none;
@button-border-radius: @border-radius;

// disabled
@button-disabled-background: #ccc;

// large
@button-large-padding: 0.3em 0.9em;
@button-large-font-size: 1.5rem;
@button-large-border-radius: @button-border-radius;

// small
@button-small-padding: 1;
@button-small-font-size: 1;
@button-small-border-radius: 1;

@button-mini-padding: 0.2em 0.6em;
@button-mini-font-size: 0.9rem;
@button-mini-border-radius: 0.2em;

// Rss button
@button-rss-background: orange;
@button-rss-background-highlight: darken(@button-rss-background, 5%);
@button-rss-text-color: #fff;

@input-width: 100%;
@input-font-size: 1em;
@input-vertical-padding: 0.3em;
@input-horizontal-padding: 0.3em;
@input-line-height: 1.2;
@input-padding: (@input-vertical-padding + ((1em - @input-line-height) / 2)) @input-horizontal-padding;
@input-background: #ffffff;
@input-border: 1px solid #ccc;
@input-border-radius: 0.3125em;
@input-color: #222;
@input-width: 100%;
@input-transition:
background-color 0.2s ease,
color 0.2s ease,
box-shadow 0.2s ease,
border-color 0.2s ease
;
@input-box-shadow: 0em 0em 0em 0em transparent inset;

@input-focus-pointer-size: 0.1em;

/* input focus */
@input-focus-background: @input-background;
@input-focus-border-color: darken(#ccc, 5%);
@input-focus-color: rgba(0, 0, 0, 0.85);
@input-focus-box-shadow: @input-focus-pointer-size 0.1em 0.3em 0 rgba(0, 0, 0, 0.6) inset;
@input-focus-border-radius: 0em @input-border-radius @input-border-radius 0em;

@input-disabled-background-color: #ddd;

/* text area focus */
@textarea-padding: 1em;
@textarea-background: transparent;
@textarea-focus-background: #eee;
@textarea-border: 1px solid #ddd;

/* text area focus */
@textarea-focus-background: @input-focus-background;
@textarea-focus-border-color: @input-focus-border-color;
@textarea-focus-color: @input-focus-color;
@textarea-focus-box-shadow: @input-focus-box-shadow;
@textarea-focus-border-radius: @input-focus-border-radius;

// form actions
@form-actions-border:  0;
@form-actions-padding: 0.5em;
@form-actions-background: #ccc;
@form-actions-margin: 0.5em 0;

// error labels
@form-error-label-background: red;
@form-error-label-color: white;

// required star
@form-required-star-color: red;
