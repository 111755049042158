@import '../core/core';
@import '../../mixins/_grid';
@import './_variables';

// Page wrapper
.wrapper {
  margin: 0 auto;
  // 1152
  max-width: 72rem; // 1024px
  width: 90%;
}

.block {
  .grid-block();
}

.block(@column) {
  .grid-block(@column);
}

.block-group {
  .grid-block-group();
}

.block-offset(@offset) {
  .grid-block-offset(@offset);
}

@media (min-width: 10em) {
  .block {
    width: 100%;
  }
}

@media (min-width: 40em) {
  .block {
    width: percentage((4 / @grid-number-blocks));
  }
}

// export grid system media query with minimal
@media (min-width: @grid-block-min-block-width) {
  .grid-generate-blocks(block);
}
