
.icon-base-class(ui-icon, @icon-font-family, @icon-generate-size-variations, '');
// .ui-icon {
// cannot extend dynamic class, so we use base class mixin
//   &:extend(.icon);
// }

.ui-button-text {
  display: none;
}

// rewrite the icons
// full list: http://api.jqueryui.com/theming/icons/

.ui-icon-closethick:before,
.ui-icon-close:before {
  content: "\e68d";
}
