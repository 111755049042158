/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*
* @group Mixins
* @description Less mixins
*/

/*
* @name .clearfix()
* @belongsTo Mixins
* @description Clears floated elements
* @example
*   .class {
*     .clearfix();
*  }
*/
.clearfix() {
  &:before,
  &:after {
    display: table;
    content: '';
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    // line-height: 0;
  }
  &:after {
    clear: both;
  }
}

/*
* @name .size($the-size)
* @description Sets the width and height property to `$the-size`
* @param @the-size The size
* @example

  .class {
    .size(50px);
  }

* @belongsTo Mixins
*/
.size(@the-size) {
  width: @the-size;
  height: @the-size;
}

/*
* @name .size($width, $height)
* @description Sets the width and height property to `$width` and `$height`
* @param $width The width of the element
* @param $height The height of the element
* @example
  .class {
    .size(50px, 60px);
  }
* @belongsTo Mixins
*/
.size(@width, @height) {
  width: @width;
  height: @height;
}

/*
* @name .opacity($opacity)
* @description Sets opacity of the element
* @param opacity The opacity
* @example
*   .class { .opacity(50); }
* @belongsTo Mixins
*/
.opacity(@opacity) {
  opacity: (@opacity / 100);
}

/*
* @name .border-radius($radius)
* @description Sets border radius of the element
* @param $radius The radius
* @belongsTo Mixins
*/
.border-radius(@radius: @border-radius) {
  border-radius: @radius;
}

/*
* @name .border-sizing($type)
* @description Sets box sizing type
* @param type - The box sizing type (default: `border-box`)
* @belongsTo Mixins
*/
.box-sizing(@type: border-box) {
  box-sizing: @type;
}

/*
* @name .box-shadow($shadow)
* @description Creates a shadow
* @param $shadow The box shadow
* @belongsTo Mixins
*/
.box-shadow(@shadow: @box-shadow) {
  box-shadow: @shadow;
}

/*
* @name .hover-active-focus(@property, @value)
* @description Creates hover, active and focus state with given property and its value
*/
.hover-active-focus(@property, @value) {
  &:hover, &:active, &:focus {
    @{property}: @value;
  }
}

/*
* @name .size-variations()
* @description Generates size variations like: mini, tiny, small, large, bug, huge, massive
*/
.size-variations() {
  &.mini {
    font-size: (@size-mini * @em);
  }
  &.tiny {
    font-size: (@size-tiny * @em);
  }
  &.small {
    font-size: (@size-small * @em);
  }
  &.large {
    font-size: (@size-large * @em);
  }
  &.big {
    font-size: (@size-big * @em);
  }
  &.huge {
    font-size: (@size-huge * @em);
  }
  &.massive {
    font-size: (@size-massive * @em);
  }
}

/*
* @name .visually-hidden()
* @description Hide only visually, but have it available for screen readers
* @param $focusable Allow focusable state?
* @see http://h5bp.com/v
* @see http://h5bp.com/p
*/
.visually-hidden(@focusable: false) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  .focusable() when (@focusable = true) {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }

  .focusable();
}

/*
* @name .hidden()
* @description Hide visually and from screen readers
* @see http://h5bp.com/u
*/
.hidden() {
  display: none;
  visibility: hidden;
}

.visible() {
  display: block;
  visibility: visible;
}

/*
* @name .invisible()
* @description Hide visually and from screen readers, but maintain layout
*/
.invisible() {
  visibility: hidden;
}

/*
 * WebKit-style focus
 *
 * @name .invisible()
 */
.tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.border-top-radius(@radius) {
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}
