/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

@import '../core/core';
@import './_variables';
@import '../animations/animations';

/*
@group Dropdown
@description Dropdown component
*/

div.dropdown-menu,
ul.dropdown-menu {
  .hidden();
  position: absolute;
  background: @dropdown-menu-background;
  padding: @dropdown-menu-padding;
  z-index: @dropdown-menu-z-index;
  color: @dropdown-menu-color;
  min-width: @dropdown-menu-min-width;
  width: auto;
  left: 0;
  top: auto;
  list-style-type: none;
  border: @dropdown-menu-border;
  border-radius: @dropdown-menu-border-radius;
  box-shadow: @dropdown-menu-box-shadow;
  overflow: visible;
  background-clip: padding-box;

  .divider {
    border: 1px solid @dropdown-menu-divider-color;
    padding: 0;
    margin: 0.2em 0;
    overflow: hidden;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li > a,
  li > a:link,
  li > a:visited,
  li > a:active,
  li > label {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: @dropdown-link-color;
    text-decoration: none;
    font-weight: normal;
    padding: @dropdown-link-padding;
    margin: 0;
    white-space: @dropdown-link-white-space;
    border-radius: @dropdown-link-border-radius;
    cursor: pointer;
  }

  li > a:last-child,
  li > label:last-child {
    border-bottom-left-radius: @dropdown-menu-border-radius;
    border-bottom-right-radius: @dropdown-menu-border-radius;
  }

  li > a:first-child,
  li > label:first-child {
    border-top-left-radius: @dropdown-menu-border-radius;
    border-top-right-radius: @dropdown-menu-border-radius;
  }

  li > label {
    display: block;
  }

  li > a:hover,
  li > label:hover {
    color: @dropdown-link-hover-color;
    background: @dropdown-link-hover-background;
  }
}

.dropdown-opened {
  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
  & > a {
    // the clicked link
    .transition();
  }
  .dropdown-menu {
    .visible();
  }
}

// dropdown icon
// .dropdown-icon {
  // cannot use class name as variable until implemented:
  // https://github.com/less/less.js/issues/1485
  // cannot extend dynamically created class, we have
  // https://github.com/less/less.js/issues/2200
  // &:extend(.icon, .icon.chevron-down all);
// }
