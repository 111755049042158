/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

@import '../core/core';
@import './_variables';
@import '../../mixins/_buttons';
@import '../../mixins/_images';

.btn {
  display: inline-block;
  padding: @button-padding;
  text-decoration: none;
  margin-bottom: 0; // For input.btn
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  .button-background(@button-background, @button-background-highlight, @button-text-color);
  color: @button-text-color;
  border: @button-border;
  border-bottom-color: darken(@button-border-color, 10%);
  border-radius: @button-border-radius;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  font-weight: normal;
  &:link, &:visited {
    text-decoration: none;
    color: @button-text-color;
    font-weight: normal;
  }

  // Hover state
  &:hover {
    color: lighten(@button-text-color, 20%);
    text-decoration: none;
  }

  // Focus state for keyboard and accessibility
  &:focus {
    .tab-focus();
    color: spin(@button-text-color, 20%);
  }

  // Active state
  &.active,
  &:active {
    background-color: @button-active-background;
    background-image: none;
    outline: 0;
    box-shadow: @button-active-box-shadow;
  }

  // Disabled state
  &.disabled,
  &[disabled] {
    cursor: default;
    background-color: @button-disabled-background;
    background-image: none;
    opacity: 65;
    box-shadow: none;
  }

  &.loading {
    .background-image("urim/form/btn_loading.svg");
    background-position: 97% center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .@{icon-base-selector-class} {
    line-height: 80%;
  }

} // end .btn

// Large
.btn.large {
  padding: @button-large-padding;
  font-size: @button-large-font-size;
  border-radius: @button-large-border-radius;
}

.btn.large [class^="icon-"],
.btn.large [class*=" icon-"] {
  margin-top: 2px;
}

// Small
.btn.small {
  padding: @button-small-padding;
  font-size: @button-small-font-size;
  border-radius: @button-small-border-radius;
}

.btn.small [class^="icon-"],
.btn.small [class*=" icon-"] {
  margin-top: 0;
}

.btn.mini {
  padding: @button-mini-padding;
  font-size: @button-mini-font-size;
  border-radius: @button-mini-border-radius;
}

.btn.mini [class^="icon-"],
.btn.mini [class*=" icon-"] {
  margin-top: -1px;
}

// Make the div behave like a button
.btn-group {
  position: relative;
  display: inline-block;
  // vertical-align: middle; // match .btn alignment given font-size hack above
  white-space: nowrap; // prevent buttons from wrapping when in tight spaces (e.g., the table on the tests page)
  font-size: 0; // Hack to remove whitespace that results from using inline-block
}

// Space out series of button groups
.btn-group + .btn-group {
  margin-left: 0.3em;
}

// Float them, remove border radius, then re-add to first and last elements
.btn-group > .btn {
  position: relative;
  border-radius: 0;
}

// Set corners individual because sometimes a single button can be in a .btn-group
// and we need :first-child and :last-child to both match
.btn-group > .btn:first-child {
  margin-left: 0;
  border-top-left-radius: @button-border-radius;
  border-bottom-left-radius: @button-border-radius;
}

.btn-group > .btn-group:last-child > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn:last-child {
  border-top-right-radius: @button-border-radius;
  border-bottom-right-radius: @button-border-radius;
}

// Custom edits for including btn-groups within btn-groups (useful for including dropdown buttons within a btn-group)
// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group > .btn:first-child {
  margin-left: 0;
  &:not(:last-child):not(.dropdown-toggle) {
    .border-right-radius(0);
  }
}
// Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu immediately after it
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  .border-left-radius(0);
}

// Custom edits for including btn-groups within btn-groups (useful for including dropdown buttons within a btn-group)
.btn-group > .btn-group {
  margin-left: -1px;
}

// last btn after btn-groupd must be shifted
.btn-group + .btn:last-child {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn.dropdown-toggle {
  border-top-right-radius: @button-border-radius;
  border-bottom-right-radius: @button-border-radius;
}

.btn-group > .btn-group:first-child {
  > .btn:last-child,
  > .dropdown-toggle {
    .border-right-radius(0);
  }
}

.btn-group > .btn-group:last-child > .btn:first-child {
  .border-left-radius(0);
}

// On active and open, don't show outline
.btn-group .dropdown-toggle:active,
.btn-group.dropdown-opened .dropdown-toggle {
  outline: 0;
}

// On hover/focus/active, bring the proper btn to front
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}

// On active and open, don't show outline
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .btn {
  margin-left: -1px;
}

.btn-group > .btn,
.btn-group > .dropdown-menu {
  font-size: @button-font-size; // redeclare as part 2 of font-size inline-block hack
}

.btn-group > .btn.small {
  font-size: @button-small-font-size; // redeclare as part 2 of font-size inline-block hack
}

.btn-group > .btn.large {
  font-size: @button-large-font-size; // redeclare as part 2 of font-size inline-block hack
}

.btn-group.dropdown-opened {

  // The clickable button for toggling the menu
  // Remove the gradient and set the same inset shadow as the :active state
  .dropdown-toggle {
    background-image: none;
    box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  }

  // Keep the hover's background when dropdown is open
  .btn.dropdown-toggle {
    // background-color: @btnBackgroundHighlight;
  }
  .btn-primary.dropdown-toggle {
    // background-color: @btnPrimaryBackgroundHighlight;
  }
  .btn-warning.dropdown-toggle {
    // background-color: @btnWarningBackgroundHighlight;
  }
  .btn-danger.dropdown-toggle {
    // background-color: @btnDangerBackgroundHighlight;
  }
  .btn-success.dropdown-toggle {
    // background-color: @btnSuccessBackgroundHighlight;
  }
  .btn-info.dropdown-toggle {
    // background-color: @btnInfoBackgroundHighlight;
  }
  .btn-inverse.dropdown-toggle {
    // background-color: @btnInverseBackgroundHighlight;
  }

}

// special
.btn-rss {
  .button-background(@button-rss-background,  @button-rss-background-highlight, @button-rss-text-color);
}
