@import './_variables';
@import '../../mixins/_images';

.load-masked {
  overflow: hidden !important;
  .load-mask {
    position: absolute;
    z-index: @load-mask-z-index;
    top: 0;
    left: 0;
    opacity: @load-mask-opacity;
    width: 100%;
    height: 100%;
    background-color: @load-mask-background-color;
    .background-image(@load-mask-loading-image);
    background-position: center center;
    background-repeat: no-repeat;
    .load-mask-msg {
      display: none;
    }
  }
}

.load-masked-relative {
  position: relative !important;
}
