body {
  padding: 0;
  margin: 0;
  border-top: 0.5em solid @footer-background;
}

#logo {
  > a {
    padding: 0;
    margin: 0;
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    float: left;
    padding: 0 3em 1em 0;
  }
  p {
    strong {
      display: block;
      font-size: 3em;
      padding-top: 0.3em;
      //margin-bottom: 0.3em;
      line-height: 100%;
    }
  }
}

#quick-phone {
  text-align: right;
  .phone {
    font-size: 3rem;
    display: block;
    .prefix {
      font-size: 1rem;
    }
  }

}

.breadcrumbs-wrapper {
  .wrapper;
  font-size: 90%;
  color: #979797;
  padding: 0.3em 0;
  a {
    color: #979797;
  }
  > span {
    float: left;
    padding-right: 0.5em;
  }
}

#main-menu {
  padding: 0;
  margin: 0;
  border-bottom: 0.2em solid #e1ebfd;
  text-align: left;
  ul {
    list-style: none;
    padding: 0;
    li {
      padding: 0 1em;
      margin: 0;
      a {
        color: #000;
        text-decoration: none;
        font-weight: normal;
        padding: 1.2em 0.5em 1.2em 0.5em;
        display: inline-block;
        position: relative;
        &:after {
          content: '';
          width: 100%;
          height: 0.3em;
          display: block;
          margin-top: 1em;
          position: absolute;
          bottom: -0.5em;
          visibility: hidden;
          left: 0;
          background: @footer-background;
        }
        &:hover {
          color: @footer-background;
          &:after {
            bottom: -0.1em;
            visibility: visible;
          }
          text-decoration: none;
        }
      }
    }
  }
}

// selected states
body.homepage #main-menu ul li.homepage a,
body.article #main-menu ul li.aktuality a,
body.page-kontakt #main-menu ul li.kontakt a,
body.page-ordinacni-hodiny #main-menu ul li.ordinacni-hodiny a,
body.page-mudr-rybar-ml #main-menu ul li.ordinacni-hodiny a,
body.page-mudr-rybar-st #main-menu ul li.ordinacni-hodiny a,
body.page-mudr-rybarova #main-menu ul li.ordinacni-hodiny a,
body.page-rehabilitace #main-menu ul li.ordinacni-hodiny a,
body.page-o-nas #main-menu ul li.o-nas a,
body.page-cenik #main-menu ul li.cenik a {
  color: @footer-background;
  &:after {
    visibility: visible;
    bottom: -0.1em;
  }
}

body.homepage {

  header {
    border-bottom: 0.1em solid #bdd2fa;
  }

  .content-wrapper {
    background-color: #abbad9;
    .background-image('homepage_bg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 2em;
    border-bottom: 0.2em solid #2d70f3;
  }

  .shortcuts {
    .clearfix();

    .dropdown-menu {
      max-width: 30em;
    }

    li.dropdown-opened > a {
      background: @taxonomy-tag-hover-background;
    }

    > ul {
      padding: 0;
      list-style: none;
    }

    ul.news-list {
      a {
        color: #000;
      }
    }
  }

  .quick-contact,
  .intro {
    h2 {
      text-transform: uppercase;
      color: @footer-background;
    }
  }
}

footer {
  margin-top: 3em;
  background: @footer-background;
  color: #fff;
  padding: 2em;
  > div {
    .wrapper;
    position: relative;
  }
  p.copyright {
    text-align: right;
    padding: 0;
    margin: 0;
  }
  .spolupracujeme {
    background: #fff;
    margin: 1em 0;
    padding: 0.5em;
    h3 {
      color: @footer-background;
      text-transform: uppercase;
      font-weight: normal;
    }
    p {
      text-align: center;
    }
  }
}

#top-link {
  position: absolute;
  right: 1em;
  top: -3.3em;
  > span {
    display: none;
  }
  &:after {
    .triangle(up, 1.5em, 1.5em, @footer-background);
    display: block;
  }
}

.main h1 {
  text-transform: uppercase;
  color: #000;
  font-weight: normal;
  line-height: 130%;
  .btn {
    font-size: 1rem;
    padding: 0 0.3em;
    vertical-align: top;
  }
}

.sidebar h2,
.main h2 {
  text-transform: uppercase;
  color: @footer-background;
}
