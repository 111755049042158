@footer-background: #0353f1;

// overrides
@taxonomy-tag-padding: 0.4em 0.7em 0.4em 0.7em;
@taxonomy-tag-color: #000;
@taxonomy-tag-border-radius: 0;
@taxonomy-tag-dot-border-radius: 0;
@taxonomy-tag-background: transparent;
@taxonomy-tag-hover-background: rgba(255, 255, 255, 0.7);

@taxonomy-tag-hover-color: #222;
@taxonomy-tag-dot-color: transparent;

// compact version
@taxonomy-tag-compact-padding: 0.2em 0.3em 0.2em 0.3em;
@taxonomy-tag-compact-border-radius: 0.5em 0.2em 0.2em 0.5em;

@link-color: @footer-background;
@link-visited-color: darken(@link-color, 15%);
@link-hover-color: darken(@link-color, 15%);
