/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

// prefix all animations
@animation-prefix: '';

@animation-flash-name: ~"@{animation-prefix}flash";
@animation-flip-name: ~"@{animation-prefix}flip";
@animation-shake-name: ~"@{animation-prefix}shake";
