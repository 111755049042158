@import '../core/_variables';

@overlay-background: rgba(0,0,0,0.7);

@dialog-background: #fff;
@dialog-shadow: 0 3px 7px rgba(0,0,0,0.3);
@dialog-border-radius: @border-radius;
@dialog-z-index: 100;
@dialog-titlebar-background: none;
@dialog-titlebar-padding: 0;
@dialog-titlebar-border: 0.2em solid #ccc;
@dialog-title-font-size: 2rem;
@dialog-title-padding: 0.5em 0.4em 0.7em 0.5em;
