@import '../core/_variables';

@dropdown-menu-min-width: 10rem;
@dropdown-menu-z-index: 1;
@dropdown-menu-background: #fff;
@dropdown-menu-color: #222;
@dropdown-menu-padding: 0.2em 0;
@dropdown-menu-border-color: #eee;
@dropdown-menu-border: 0px solid @dropdown-menu-border-color;
@dropdown-menu-border-radius: @border-radius;
@dropdown-menu-box-shadow: 0 0.2em 0.7em rgba(0, 0, 0, 0.3);
@dropdown-menu-divider-color: #eee;

@dropdown-link-white-space: nowrap;
@dropdown-link-border-radius: 0;
@dropdown-link-color: #222;
@dropdown-link-padding: 0.3em 0.5em;

@dropdown-link-hover-background: #eee;
@dropdown-link-hover-color: #000;
