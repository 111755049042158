/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
@import '../core/core';
@import './_variables';

/*
@group Modal
@description Modal component
*/
.modal {
  display: none;
}

/**
 * Effect for modal opened.
 * Should be applied on the site-wrapper element which does not contain the modal window
 */
.modal-opened-effect {
  filter: @modal-site-wrapper-opened-effect;
}
