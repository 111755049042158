// custom styles which can by applied by the rich editor

/*! @editor-style: Tabulka */
table.tabulka {
  width: 100%;
  padding: 0.5em;
  empty-cells: show;
  border-collapse: separate;
  td {
    border: 0.1em solid #ccc;
    padding: 0.3em;
  }

  tr:hover td {
    background-color: #f1f5fe;
  }
}

/*! @editor-style: Avatar */
img.avatar {
  border-radius: 100%;
  border: 1em solid #eef0f1;
}

/*! @editor-style: Tabulka ordinačních hodin */
table.ordinacni-hodiny {
  width: 100%;
  padding: 0.5em;
  border: 0;
  tr:hover {
    td, th {
      background-color: #c8d9fa;
    }
  }
  th, td {
    padding: 0.3em;
    background-color: #f1f5fe;
    color: inherit;
    font: inherit;
    margin-bottom: 0.1em;
    border-bottom: 0.1em solid #c8d9fa;
  }
  th {
    font-weight: bold;
    width: 30%;
    max-width: 10em;
    text-align: left;
  }
}

/*! @editor-style: Varování */
div.varovani {
  padding: 0.5em;
  background: #ffc6c6;
  margin: 0 0 1em 0;
  font-size: 110%;
}
