
@import './_variables';
@import '../../mixins/_core';

.nav {
  margin-left: 0;
  margin-bottom: 1rem;
  list-style: none;
  padding: 0 0 0 0.5rem;
}

// Make links block level
.nav > li > a {
  display: inline-block;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: @navigation-hover-color;
}

.nav > li > a .icon {
  line-height: 80%;
}
