/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
@import '../components/core/_variables';
@import '../components/images/_variables';

/*
* @name .background-image($img)
* @description Sets an background image
* @param $img The img filename
*/
.background-image(@img) when (@using-asset-manager = true) {
  background-image: image-url(@img);
}

.background-image(@img) when (@using-asset-manager = false) {
  // the @img needs to be prefixed with base directory here
  background-image: url('@{base-img-dir}/@{img}');
}

/**
 * Sharpen an image if it has become blurry due to upscaling or downscaling
 *
 * @name .sharpen-image()
 * @see https://developer.mozilla.org/en/CSS/image-rendering
 */
.sharpen-image() {
  image-rendering: -moz-crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE 7+ */
}

/**
 * When photos are upscaled or downscaled, they often get blurry.
 * Don't use this on flat color images, they will still appear blurry.
 *
 * @name .high-quality-image()
 * @see https://developer.mozilla.org/en/CSS/image-rendering
 */
.high-quality-image() {
  image-rendering: optimizeQuality;
  -ms-interpolation-mode: bicubic; /* IE 7+ */
}

/**
 * Optimizes the rendering on images so they appear faster, but at a lower quality. Useful for <video>
 *
 * @name .low-quality-image()
 */
.low-quality-image() {
  image-rendering: optimizeSpeed;
}
