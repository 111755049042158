/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*
* @name .font-face($font-family, $font-name, $font-svg-id, $font-path: fonts)
* @description Adds a font face
* @param $font-family Font family name
* @param $font-fame Font filename
* @param $font-svg-id Font SVG id
* @param $font-path Font path
*/
.font-face(@font-family, @font-name, @font-svg-id, @font-path: @font-path) when (@using-asset-manager = true) {
  @font-face {
    font-family: @font-family;
    src: font-url('@{font-name}.eot');
    src: font-url('@{font-name}.eot') format('embedded-opentype'),
    font-url('@{font-name}.woff') format('woff'),
    font-url('@{font-name}.ttf') format('truetype'),
    font-url('@{font-name}.svg#@{font-svg-id}') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

.font-face(@font-family, @font-name, @font-svg-id, @font-path: @font-path) when (@using-asset-manager = false) {
  @font-face {
    font-family: @font-family;
    src: url('@{font-path}/@{font-name}.eot');
    src: url('@{font-path}/@{font-name}.eot') format('embedded-opentype'),
    url('@{font-path}/@{font-name}.woff') format('woff'),
    url('@{font-path}/@{font-name}.ttf') format('truetype'),
    url('@{font-path}/@{font-name}.svg#@{font-svg-id}') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}
