// Messages

// Basic settings
@message-padding:                  0.5em;
@message-margin:                   0.4em 0;
@message-border-radius:            0.3em;
@message-link-font-weight:         bold;

// States
@message-state-success-text:       #3c763d;
@message-state-success-bg:         #dff0d8;
@message-state-success-border:     darken(spin(@message-state-success-bg, -10), 5%);

@message-state-info-text:          #31708f;
@message-state-info-bg:            #d9edf7;
@message-state-info-border:        darken(spin(@message-state-info-bg, -10), 7%);

@message-state-warning-text:       #8a6d3b;
@message-state-warning-bg:         #fcf8e3;
@message-state-warning-border:     darken(spin(@message-state-warning-bg, -10), 5%);

@message-state-danger-text:        #a94442;
@message-state-danger-bg:          #f2dede;
@message-state-danger-border:      darken(spin(@message-state-danger-bg, -10), 5%);
