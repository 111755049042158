@import './_variables';

ul.tags-list {
  list-style-type: none;
  padding-left: 0.5em;
  > li {
    display: inline-block;
    padding-right: 0.5em;
    margin: 0.5em 0;
  }
  > li:last-child {
    padding-right: 0;
  }
}

span.tag,
a.tag {
  font-weight: normal;
  text-decoration: none;
  color: @taxonomy-tag-color;
  padding: @taxonomy-tag-padding;
  border-radius: @taxonomy-tag-border-radius;
  background: @taxonomy-tag-background;
  display: inline-block;
  &:not(.compact) {
    position: relative;
  }
  // the dot inside the tag
  &:not(.compact):before {
    content: '';
    width: @taxonomy-tag-dot-width;
    height: @taxonomy-tag-dot-height;
    background: @taxonomy-tag-dot-color;
    border-radius: @taxonomy-tag-dot-border-radius;
    position: absolute;
    top: 35%;
    left: 0.5em;
  }
  &.compact {
    padding: @taxonomy-tag-compact-padding;
    border-radius: @taxonomy-tag-compact-border-radius;
  }
  > img {
    max-height: 100%;
    vertical-align: top;
  }
}

a.tag:hover {
  background: @taxonomy-tag-hover-background;
  color: @taxonomy-tag-hover-color;
}

.tag-cloud {
  margin: 1em 0;
  padding: 0;
}

.tag-cloud li {
  display: inline-block;
  margin-right: 0.3rem;
  font-size: 1rem;
  padding: 0;
  line-height: 1.1;
  &:last-child {
    margin-right: 0;
  }
}

.tag-cloud li a {
  letter-spacing: 0.009rem;
}

.tag-cloud li.not-frequent a,
.tag-cloud li.not-popular a {
  font-size: 1em;
}

.tag-cloud li.not-very-frequent a
.tag-cloud li.not-very-popular a {
  font-size: 1.3em;
}

.tag-cloud li.somewhat-frequent a,
.tag-cloud li.somewhat-popular a {
  font-size: 1.6em;
}

.tag-cloud li.frequent a,
.tag-cloud li.popular a {
  font-size: 1.9em;
}

.tag-cloud li.very-frequent a,
.tag-cloud li.very-popular a {
  font-size: 2.2em;
}

.tag-cloud li.ultra-frequent a,
.tag-cloud li.ultra-popular a {
  font-size: 2.5rem;
}
