
@import '../../mixins/_normalize';
@import './_variables';

/*
@group Normalize
@description Uses normalize.css to normalize HTML elements.
@source
*/

.normalize();

html {
  overflow-y: scroll;
}

// Setups all elements to use `border box` for the layout
*, *:after, *:before {
  box-sizing: border-box;
}
