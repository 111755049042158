@media screen and (max-width: 500px) {
  #main-menu {
    ul {
      li {
        display: block;
        width: 100%;
        text-align: center;
        a {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {

  .section-imagery {
    height: 0.5em;
    background: @footer-background;
  }

  .sidebar {
    text-align: center;
  }

  .main,
  .sidebar,
  .content-wrapper {
    padding: 0.7em;
  }

  #quick-phone {
    text-align: center;
  }

  #logo {
    text-align: center;
    strong {
      font-size: 1rem;
    }
    img {
      padding: 0;
      float: none;
    }
  }
  #main-menu {
    ul {
      text-align: center;
      li {
        display: block;
        width: 100%;
      }
      li a {
        display: block;
        width: 100%;
        font-size: 1.3rem;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #main-menu ul li {
    padding: 0 2em;
  }
}

@media screen and (min-width: 1050px) {

  div.section-wrapper,
  div.site-container {
    .wrapper();
    > div {
      .grid-block-group();
    }
  }

  .main {
    .grid-block(12);
    &.with-sidebar {
      .grid-block(8);
    }
  }

  .sidebar {
    .grid-block(4);
  }

  body.homepage {
    .sidebar {
      .grid-block(6);
    }
    .main.with-sidebar {
      .grid-block(6);
    }
    .shortcuts {
      .grid-block-group();
      > ul {
        .grid-block(10);
      }
    }
    .shortcuts h2 {
      display: block;
      text-transform: uppercase;
      .grid-block(2);
      padding: 0.3em;
      font-weight: normal;
      background: rgba(255, 255, 255, 0.7);
    }
    .quick-contact,
    .intro {
      .grid-block(6);
    }
  }

  header {
    #logo {
      padding-left: 1rem;
    }
    .grid-block-group;
    &.with-phone-number #logo {
      .grid-block(7);
    }
    &.with-phone-number #quick-phone {
      .grid-block(5);
    }
  }

  #main-menu ul {
    .wrapper;
    li a {
      font-size: 1.5rem;
      padding: 1.2em 0.5em 1.2em 0.5em;
    }
  }

  .main h1 {
    margin-top: -2em;
    background: rgba(255, 255, 255, 0.6);
    padding: 0.5em;
  }

  .section-imagery {
    height: 300px;
    background: transparent;
    overflow: hidden;
    position: relative;
    z-index: -1; // move back

    img {
      width: 100%;
      text-align: center;
      max-width: none;
    }
    > div {
      background-position: center center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      visibility: visible;
      transition: all 1s ease-in-out;
    }

    .hidden {
      visibility: hidden;
      background-position: 10% center;
      opacity: 0;
    }

    // all section images here:
    .rehabilitace-00 {
      .background-image('sections/rehabilitace_00.jpg');
    }
    .mudr-rybarova-01 {
      .background-image('sections/mudr_rybarova_01.jpg');
    }
    .mudr-rybar-st-01 {
      .background-image('sections/mudr_rybar_st_01.jpg');
    }
    .mudr-rybar-st-02 {
      .background-image('sections/mudr_rybar_st_02.jpg');
    }
    .mudr-rybar-ml-01 {
      .background-image('sections/mudr_rybar_ml_01.jpg');
    }
    .mudr-rybar-ml-02 {
      .background-image('sections/mudr_rybar_ml_02.jpg');
    }
    .o-nas-01 {
      .background-image('sections/o_nas_01.jpg');
    }
    .kontakt-01 {
      .background-image('sections/kontakt_01.jpg');
    }
    .cenik-01 {
      .background-image('sections/cenik_01.jpg');
    }
    .ordinacni-hodiny-01 {
      .background-image('sections/ordinacni_hodiny_01.jpg');
    }
    .article-01 {
      .background-image('sections/article_01.jpg');
    }
    .error404-01 {
      .background-image('sections/error404_01.jpg');
    }
  }

  #main-menu {
    ul {
      li:first-child {
        padding-left: 0;
      }
      li {
        display: inline-block;
        a {
          transition: all 0.1s ease-in;
          &:after {
            transition: all 0.1s ease-in-out;
          }
        }
      }
    }
  }
}
