@import '../components/breadcrumbs/_variables';

/**
 * Breadcrumbs navigation
 *
 * @name .breadcrumbs()
 * @see http://www.data-vocabulary.org/Breadcrumb/
 * @see http://css-tricks.com/markup-for-breadcrumbs/#comment-85333
 * @markup
<nav class="breadcrumbs">
    <div>
    <span class="home" itemscope itemtype="http://data-vocabulary.org/Breadcrumb">
      <a href="#" itemprop="url" rel="index"><span itemprop="title">Home</span></a>
    </span>
    <span itemscope itemtype="http://data-vocabulary.org/Breadcrumb">
      <a href="#" itemprop="url"><span itemprop="title">Section</span></a>
    </span>
    <span itemscope itemtype="http://data-vocabulary.org/Breadcrumb">
      <a href="#" itemprop="url"><span itemprop="title">Subsection</span></a>
    </span>
    <span itemscope itemtype="http://data-vocabulary.org/Breadcrumb">
      <a href="#" itemprop="url"><span itemprop="title">Another subsection</span></a>
    </span>
        <span class="current">Current item</span>
    </div>
</nav>
*/
.breadcrumbs() {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  width: auto;
  background: @breadcrumbs-background;
  div {
    display: inline-block;
  }
  a {
    font-weight: normal;
  }
  span[itemtype]:last-child:after {
    display: none;
  }
  span[itemtype]:after {
    padding: 0 5px;
    content: @breadcrumbs-divider-content;
    color: @breadcrumbs-divider-color;
    font-size: @breadcrumbs-divider-font-size;
    font-family: @breadcrumbs-divider-font-family;
  }
  span[itemtype], span.current {
    display: inline-block;
    vertical-align: top;
    font-weight: normal;
  }
}
