// --------------------------------------------------
// Icon mixins
// --------------------------------------------------
@import './_fonts';

/**
 * Creates icon font face
 *
 * @name .icon-font-face()
 * @param string $icon-font-family The font family
 * @param string $icon-font-name The filename of the font
 * @param string $icon-font-svg-id The SVG id
 * @param string $font-path The path to font directory
 * @belongsTo Icons
*/
.icon-font-face(@icon-font-family, @icon-font-name, @icon-font-svg-id, @font-path: @font-path) {
  .font-face(@icon-font-family, @icon-font-name, @icon-font-svg-id, @font-path);
}

/*
* @name .icon-base-class($class, $font-family, $generate-size-variations, $element)
* @description Creates a base class for an icon
* @param $class The class name like `my-icon`
* @param $font-family The font family
* @param $generate-size-variations Generate size variations?
* @param $element The HTML element (default is `span`)
*/
.icon-base-class(@class, @font-family, @generate-size-variations: true, @element: span) {
  @selector: ~"@{element}.@{class}";
  @{selector} {
    font-family: @font-family;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    // Better font rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .generate-size-variations() when (@generate-size-variations) {
    // generate size variations
    @{selector} {
      .size-variations();
    }
  }

  .generate-size-variations();
}

/*
* @name .icon-base-attribute-selector($prefix, $font-family, $element)
* @description Creates a base attribute selector for an icon
* @param $prefix The class name like `my-icon-`
* @param $font-family The font family
* @param $element The HTML element (default is `span`)
*/
.icon-base-attribute-selector(@prefix, @font-family, @element: span) {
  @selector: ~'@{element}[class^="@{prefix}"], @{element}[class*=" @{prefix}"]';
  @{selector} {
    font-family: @font-family;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    // Better font rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
