// --------------------------------------------------
// Icons
// --------------------------------------------------

/**
* @group Icons
* @description Icons
* @variable $icon-font-family Icon font family
* @variable $icon-font-name The filename of the font
* @variable $icon-font-svg-id The Id of SVG variant
 */

@import '../core/core';
@import '../../mixins/_icons';
@import '../typography/_variables';
@import './_variables';
@import './_base';
@import './_aliases';
