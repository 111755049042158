@import './_variables';
@import '../core/core';

.pager {
  margin: @pager-margin;
  text-align: @pager-text-align;
  list-style: none;
  .clearfix();
}

.pager li {
  display: inline;
}

.pager li > a,
.pager li > span {
  display: inline-block;
  padding: @pager-item-padding;
  background: @pager-item-background;
  border: @pager-item-border;
  border-radius: @pager-item-border-radius;
}

.pager li > a:hover {
  text-decoration: none;
  background-color: @pager-item-hover-background;
}

.pager .next > a,
.pager .next > span {
  float: right;
}

.pager .previous > a,
.pager .previous > span {
  float: left;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > span {
  color: @pager-item-disabled-color;
  background: @pager-item-disabled-background;
  cursor: default;
}
