/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*
* @name Animation mixins
* @belongsTo Animations
*/

/**
 * Creates a transition
 *
 * @name .transition()
 * @param string $prop The property
 * @param string $time The time
 * @param string $ease The easing
 * @param string $delay The delay
 */
.transition (@prop: all, @time: 0.5s, @ease: linear, @delay: 0s) {
  transition: @prop @time @ease @delay;
}

// http://designshack.net/articles/css/10-less-css-examples-you-should-steal-for-your-projects/
.transform (@rotate: 90deg, @scale: 1, @skew: 1deg, @translate: 10px) {
  transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
}

/*
* @name .disable-transition()
* @description Disables transition effects
*/
.disable-transition() {
  transition-property: none;
}
