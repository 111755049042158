// --------------------------------------------------
// Links
// --------------------------------------------------

@import '../core/core';
@import '../animations/animations';
@import './_variables';

// Heres a cool way to remember the order of the four selectors, "LVHA (link visited hover active)",....
// Just think 'LOVE' (LV) and 'HATE' (HA)....
// a:link
// a:visited
// a:hover
// a:active
a:link {
  color: @link-color;
  text-decoration: @link-text-decoration;
  font-weight: @link-font-weight;
}

// Visited link
a:visited {
  color: @link-visited-color;
  text-decoration: @link-visited-text-decoration;
}

a:active,
a:hover {
  color: @link-hover-color;
  text-decoration: @link-hover-text-decoration;
}

a:focus {
  .tab-focus();
}
