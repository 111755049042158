// --------------------------------------------------
// Behavior options
// --------------------------------------------------

// the functions like font-url, image-url will not be available
@using-asset-manager: false; // using asset manager?


// --------------------------------------------------
// Sizes
// --------------------------------------------------
@size-mini:           0.7;
@size-tiny:           0.8;
@size-small:          0.875;
@size-large:          1.3;
@size-big:            1.5;
@size-huge:           3;
@size-massive:        5;

// --------------------------------------------------
// Calculation utilities
// --------------------------------------------------
@em:                  1em;
@golden-ratio:        1.618033988749;

// --------------------------------------------------
// Various settings
// --------------------------------------------------
@border-radius:       0.2em; // Base border radius
@box-shadow:          1px 1px 2px rgba(0,0,0,0.25); // Base box shadow
