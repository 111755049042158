
@progress-bar-background:               #566a89;
@progress-bar-height:                   0.2rem;
@progress-bar-z-index:                  1031;
@progress-bar-box-shadow:               0 0 10px #1954af, 0 0 5px #2b6ed6;

// Spinner
@progress-bar-spinner-style:            4px double transparent;
@progress-bar-spinner-width:            1em;
@progress-bar-spinner-height:           1em;
@progress-bar-spinner-position-left:    1em;
@progress-bar-spinner-position-right:   auto;
@progress-bar-spinner-position-bottom:  auto;
@progress-bar-spinner-position-top:     1em;
