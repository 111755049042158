@pager-padding: 0.5em;
@pager-margin: 1em 0;
@pager-line-height: 1;

@pager-text-align: center;
@pager-item-background: inherit;
@pager-item-hover-background: #f5f5f5;
@pager-item-disabled-background: inherit;
@pager-item-disabled-color: #ccc;
@pager-item-padding: 5px 14px;
@pager-item-border: 1px solid #ddd;
@pager-item-border-radius: 2em;
