/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

@import './_variables';
@import '../../mixins/_core';

.tabbable {
  .clearfix();
  border-style: solid;
  border-color: #222;
  border-width: 0;
  // Make the tab content grow correctly
  > .nav-tabs {
    margin-bottom: 0;
  }
  > div,
  .tabbable-content {
    overflow: auto;
    padding: 0 1em 0 1em;
    border-style: solid;
    border-color: #ddd;
  }
  // Tabs on top
  &.tabbable-bordered {
    border-radius: 4px;
    > .nav-tabs {
      padding-left: 0;
    }
    > .tab-content {
      border-width: 0 1px 1px 1px;
      padding: 20px 20px 10px 20px;
      border-radius: 0 0 4px 4px;
    }
  }
}

// Show/hide tabbable areas
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}

.tab-content > .active,
.pill-content > .active {
  display: block;
}

// Give the tabs something to sit on
.nav-tabs {
  border-bottom: 1px solid #ddd;
}
// Make the list-items overlay the bottom border
.nav-tabs > li {
  margin-bottom: -1px;
}

// Actual tabs (as links)
.nav-tabs > li > a {
  padding: 0.5em 0.6em;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0.2em 0.2em 0 0;
  font-size: 1.2rem;
  font-weight: normal;
  text-decoration: none;
  &:hover,
  &:focus {
    border-color: #ddd;
  }
}

// Active state, and it's :hover/:focus to override normal :hover/:focus
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  color: #222;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}

.nav-tabs,
.nav-pills {
  .clearfix();
}

.nav-tabs > li,
.nav-pills > li {
  float: left;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 0.5em;
  padding-left: 0.5em;
  margin-right: 0.2em;
  line-height: 1;
}
