/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*
* @name Typography mixins
* @belongsTo Mixins
*/

/*
@name .wrap-words()
@description Wrap words
*/
.wrap-words() {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/*
@name .selection($text-color, $background-color)
@description Text selection highlight
@param $text-color The color of text
@param $background-color Background of the selection
*/
.selection(@text-color, @background-color: transparent) {
  ::selection {
    color: @text-color;
    background: @background-color;
  }
}

/*
* @name .text-left()
* @description Align the text to the `left`
*/
.text-left() {
  text-align: left;
}

/*
* @name .text-right()
* @description Align the text to the `right`
*/
.text-right() {
  text-align: right;
}

/*
* @name .text-center()
* @description Align the text to the `center`
*/
.text-center() {
  text-align: center;
}

/*
* @name .text-justify()
* @description Stretches the lines so that each line has equal width (like in newspapers and magazines)
*/
.text-justify() {
  text-align: justify;
}

/*
* @name .drop-cap($font-family, $font-size, $line-height)
* @description Creates a `drop cap` of first letter
*/
.drop-cap(@font-family: inherit, @font-size: 400%, @line-height: 0.9) {
  &::first-letter {
    float: left;
    padding: 0 0.1em 0 0;
    color: inherit;
    text-transform: uppercase;
    font-weight: bold;
    font-size: @font-size;
    font-family: @font-family;
    line-height: @line-height;
  }
}

.vertical-rhythm-base(@font-size, @margin-top: 0, @margin-bottom: 0, @leading: 0, @unit: 1em) {
  font-size: @font-size;
  line-height: ceil((@font-size + @leading) / @unit) * @unit;
  margin-bottom: ceil((@margin-bottom) / @unit) * @unit;
  margin-top: ceil((@margin-top) / @unit) * @unit;
  sup {
    vertical-align: top;
    font-size: 65%;
    position: relative;
    top: 0 - (@font-size / 4);
  }
  sub {
    vertical-align: bottom;
    font-size: 65%;
    position: relative;
    bottom: 0 - (@font-size / 4);
  }
  * {
    vertical-align: bottom;
  }
}

.vertical-rhythm(@font-size) {
  @margin: round(@font-size / 2);
  h1 {
    .vertical-rhythm-base(round(@font-size * 2.8), @margin);
  }
  h2 {
    .vertical-rhythm-base(round(@font-size * 1.42), @margin);
  }
  h3 {
    .vertical-rhythm-base(round(@font-size * 1.3), @margin);
  }
  h4 {
    .vertical-rhythm-base(round(@font-size * 1.2), @margin);
  }
  p, ul, pre, code {
    .vertical-rhythm-base(@font-size, @margin);
  }
  li {
    .vertical-rhythm-base(@font-size);
  }
}
