/**
 * This file is part of U'rim.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*
* @name .message-variant($background; $border; $text-color)
* @description Creates a message variant based on the background, border and text color
* @belongsTo Messages
*/
.message-variant(@background; @border; @text-color) {
  background-color: @background;
  border-color: @border;
  color: @text-color;
  hr {
    border-top-color: darken(@border, 5%);
  }
  a {
    color: darken(@text-color, 10%);
  }
}
