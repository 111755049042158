
@import './_variables';
@import '_animations';

/* Make the entire page show a busy cursor */
.nprogress-busy body {
  cursor: progress;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
  .bar {
    background: @progress-bar-background;
    position: fixed;
    z-index: @progress-bar-z-index;
    top: 0;
    left: 0;
    width: 100%;
    height: @progress-bar-height;
  }

  .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: @progress-bar-box-shadow;
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px);
  }

  .spinner {
    display: block;
    position: fixed;
    z-index: @progress-bar-z-index;
    top: @progress-bar-spinner-position-top;
    right: @progress-bar-spinner-position-right;
    left: @progress-bar-spinner-position-left;
    bottom: @progress-bar-spinner-position-bottom;
  }

  .spinner-icon {
    width: @progress-bar-spinner-width;
    height: @progress-bar-spinner-height;
    box-sizing: border-box;
    border: @progress-bar-spinner-style;
    border-top-color: @progress-bar-background;
    border-left-color: @progress-bar-background;
    border-radius: 100%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }

}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

