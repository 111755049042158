// --------------------------------------------------
// Breadcrumbs
// --------------------------------------------------

@import '../../mixins/_breadcrumbs';
@import './_variables';

nav.breadcrumbs {
  .breadcrumbs();
}
