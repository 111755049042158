// --------------------------------------------------
// Custom styles for jQuery UI
// --------------------------------------------------
@import (reference) '../../mixins/_core';
@import (reference) '../../mixins/_icons';
@import (reference) '../../mixins/_animations';
@import (reference) '../icons/_variables';
@import (reference) './_variables';

@import './_core';
@import './_dialog';
@import './_button';
@import './_icons';
@import './_draggable';
@import './_resizable';
@import './_selectable';
@import './_sortable';
@import './_tabs';
