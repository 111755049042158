/**
 * Base icon set
 * @name .icon
 * @description Icon
 */

.icon-font-face(@icon-font-family, @icon-font-name, @icon-font-svg-id);
.icon-base-class(@icon-base-selector-class, @icon-font-family, @icon-generate-size-variations, @icon-selector-element);

@{icon-selector-element}.@{icon-base-selector-class}.add-to-list:before {
  content: "\e600";
}
@{icon-selector-element}.@{icon-base-selector-class}.classic-computer:before {
  content: "\e601";
}
@{icon-selector-element}.@{icon-base-selector-class}.controller-fast-backward:before {
  content: "\e602";
}
@{icon-selector-element}.@{icon-base-selector-class}.creative-commons-attribution:before {
  content: "\e603";
}
@{icon-selector-element}.@{icon-base-selector-class}.creative-commons-noderivs:before {
  content: "\e604";
}
@{icon-selector-element}.@{icon-base-selector-class}.creative-commons-noncommercial-eu:before {
  content: "\e605";
}
@{icon-selector-element}.@{icon-base-selector-class}.creative-commons-noncommercial-us:before {
  content: "\e606";
}
@{icon-selector-element}.@{icon-base-selector-class}.creative-commons-public-domain:before {
  content: "\e607";
}
@{icon-selector-element}.@{icon-base-selector-class}.creative-commons-remix:before {
  content: "\e608";
}
@{icon-selector-element}.@{icon-base-selector-class}.creative-commons-share:before {
  content: "\e609";
}
@{icon-selector-element}.@{icon-base-selector-class}.creative-commons-sharealike:before {
  content: "\e60a";
}
@{icon-selector-element}.@{icon-base-selector-class}.creative-commons:before {
  content: "\e60b";
}
@{icon-selector-element}.@{icon-base-selector-class}.document-landscape:before {
  content: "\e60c";
}
@{icon-selector-element}.@{icon-base-selector-class}.remove-user:before {
  content: "\e60d";
}
@{icon-selector-element}.@{icon-base-selector-class}.warning:before {
  content: "\e60e";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-bold-down:before {
  content: "\e60f";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-bold-left:before {
  content: "\e610";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-bold-right:before {
  content: "\e611";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-bold-up:before {
  content: "\e612";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-down:before {
  content: "\e613";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-left:before {
  content: "\e614";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-long-down:before {
  content: "\e615";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-long-left:before {
  content: "\e616";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-long-right:before {
  content: "\e617";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-long-up:before {
  content: "\e618";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-right:before {
  content: "\e619";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-up:before {
  content: "\e61a";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-with-circle-down:before {
  content: "\e61b";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-with-circle-left:before {
  content: "\e61c";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-with-circle-right:before {
  content: "\e61d";
}
@{icon-selector-element}.@{icon-base-selector-class}.arrow-with-circle-up:before {
  content: "\e61e";
}
@{icon-selector-element}.@{icon-base-selector-class}.bookmark:before {
  content: "\e61f";
}
@{icon-selector-element}.@{icon-base-selector-class}.bookmarks:before {
  content: "\e620";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-down:before {
  content: "\e621";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-left:before {
  content: "\e622";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-right:before {
  content: "\e623";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-small-down:before {
  content: "\e624";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-small-left:before {
  content: "\e625";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-small-right:before {
  content: "\e626";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-small-up:before {
  content: "\e627";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-thin-down:before {
  content: "\e628";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-thin-left:before {
  content: "\e629";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-thin-right:before {
  content: "\e62a";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-thin-up:before {
  content: "\e62b";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-up:before {
  content: "\e62c";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-with-circle-down:before {
  content: "\e62d";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-with-circle-left:before {
  content: "\e62e";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-with-circle-right:before {
  content: "\e62f";
}
@{icon-selector-element}.@{icon-base-selector-class}.chevron-with-circle-up:before {
  content: "\e630";
}
@{icon-selector-element}.@{icon-base-selector-class}.cloud:before {
  content: "\e631";
}
@{icon-selector-element}.@{icon-base-selector-class}.controller-fast-forward:before {
  content: "\e632";
}
@{icon-selector-element}.@{icon-base-selector-class}.controller-jump-to-start:before {
  content: "\e633";
}
@{icon-selector-element}.@{icon-base-selector-class}.controller-next:before {
  content: "\e634";
}
@{icon-selector-element}.@{icon-base-selector-class}.controller-paus:before {
  content: "\e635";
}
@{icon-selector-element}.@{icon-base-selector-class}.controller-play:before {
  content: "\e636";
}
@{icon-selector-element}.@{icon-base-selector-class}.controller-record:before {
  content: "\e637";
}
@{icon-selector-element}.@{icon-base-selector-class}.controller-stop:before {
  content: "\e638";
}
@{icon-selector-element}.@{icon-base-selector-class}.controller-volume:before {
  content: "\e639";
}
@{icon-selector-element}.@{icon-base-selector-class}.dot-single:before {
  content: "\e63a";
}
@{icon-selector-element}.@{icon-base-selector-class}.dots-three-horizontal:before {
  content: "\e63b";
}
@{icon-selector-element}.@{icon-base-selector-class}.dots-three-vertical:before {
  content: "\e63c";
}
@{icon-selector-element}.@{icon-base-selector-class}.dots-two-horizontal:before {
  content: "\e63d";
}
@{icon-selector-element}.@{icon-base-selector-class}.dots-two-vertical:before {
  content: "\e63e";
}
@{icon-selector-element}.@{icon-base-selector-class}.download:before {
  content: "\e63f";
}
@{icon-selector-element}.@{icon-base-selector-class}.emoji-flirt:before {
  content: "\e640";
}
@{icon-selector-element}.@{icon-base-selector-class}.flow-branch:before {
  content: "\e641";
}
@{icon-selector-element}.@{icon-base-selector-class}.flow-cascade:before {
  content: "\e642";
}
@{icon-selector-element}.@{icon-base-selector-class}.flow-line:before {
  content: "\e643";
}
@{icon-selector-element}.@{icon-base-selector-class}.flow-parallel:before {
  content: "\e644";
}
@{icon-selector-element}.@{icon-base-selector-class}.flow-tree:before {
  content: "\e645";
}
@{icon-selector-element}.@{icon-base-selector-class}.install:before {
  content: "\e646";
}
@{icon-selector-element}.@{icon-base-selector-class}.layers:before {
  content: "\e647";
}
@{icon-selector-element}.@{icon-base-selector-class}.open-book:before {
  content: "\e648";
}
@{icon-selector-element}.@{icon-base-selector-class}.resize-100:before {
  content: "\e649";
}
@{icon-selector-element}.@{icon-base-selector-class}.resize-full-screen:before {
  content: "\e64a";
}
@{icon-selector-element}.@{icon-base-selector-class}.save:before {
  content: "\e64b";
}
@{icon-selector-element}.@{icon-base-selector-class}.select-arrows:before {
  content: "\e64c";
}
@{icon-selector-element}.@{icon-base-selector-class}.sound-mute:before {
  content: "\e64d";
}
@{icon-selector-element}.@{icon-base-selector-class}.sound:before {
  content: "\e64e";
}
@{icon-selector-element}.@{icon-base-selector-class}.trash:before {
  content: "\e64f";
}
@{icon-selector-element}.@{icon-base-selector-class}.triangle-down:before {
  content: "\e650";
}
@{icon-selector-element}.@{icon-base-selector-class}.triangle-left:before {
  content: "\e651";
}
@{icon-selector-element}.@{icon-base-selector-class}.triangle-right:before {
  content: "\e652";
}
@{icon-selector-element}.@{icon-base-selector-class}.triangle-up:before {
  content: "\e653";
}
@{icon-selector-element}.@{icon-base-selector-class}.uninstall:before {
  content: "\e654";
}
@{icon-selector-element}.@{icon-base-selector-class}.upload-to-cloud:before {
  content: "\e655";
}
@{icon-selector-element}.@{icon-base-selector-class}.upload:before {
  content: "\e656";
}
@{icon-selector-element}.@{icon-base-selector-class}.add-user:before {
  content: "\e657";
}
@{icon-selector-element}.@{icon-base-selector-class}.address:before {
  content: "\e658";
}
@{icon-selector-element}.@{icon-base-selector-class}.adjust:before {
  content: "\e659";
}
@{icon-selector-element}.@{icon-base-selector-class}.air:before {
  content: "\e65a";
}
@{icon-selector-element}.@{icon-base-selector-class}.aircraft-landing:before {
  content: "\e65b";
}
@{icon-selector-element}.@{icon-base-selector-class}.aircraft-take-off:before {
  content: "\e65c";
}
@{icon-selector-element}.@{icon-base-selector-class}.aircraft:before {
  content: "\e65d";
}
@{icon-selector-element}.@{icon-base-selector-class}.align-bottom:before {
  content: "\e65e";
}
@{icon-selector-element}.@{icon-base-selector-class}.align-horizontal-middle:before {
  content: "\e65f";
}
@{icon-selector-element}.@{icon-base-selector-class}.align-left:before {
  content: "\e660";
}
@{icon-selector-element}.@{icon-base-selector-class}.align-right:before {
  content: "\e661";
}
@{icon-selector-element}.@{icon-base-selector-class}.align-top:before {
  content: "\e662";
}
@{icon-selector-element}.@{icon-base-selector-class}.align-vertical-middle:before {
  content: "\e663";
}
@{icon-selector-element}.@{icon-base-selector-class}.archive:before {
  content: "\e664";
}
@{icon-selector-element}.@{icon-base-selector-class}.area-graph:before {
  content: "\e665";
}
@{icon-selector-element}.@{icon-base-selector-class}.attachment:before {
  content: "\e666";
}
@{icon-selector-element}.@{icon-base-selector-class}.awareness-ribbon:before {
  content: "\e667";
}
@{icon-selector-element}.@{icon-base-selector-class}.back-in-time:before {
  content: "\e668";
}
@{icon-selector-element}.@{icon-base-selector-class}.back:before {
  content: "\e669";
}
@{icon-selector-element}.@{icon-base-selector-class}.bar-graph:before {
  content: "\e66a";
}
@{icon-selector-element}.@{icon-base-selector-class}.battery:before {
  content: "\e66b";
}
@{icon-selector-element}.@{icon-base-selector-class}.beamed-note:before {
  content: "\e66c";
}
@{icon-selector-element}.@{icon-base-selector-class}.bell:before {
  content: "\e66d";
}
@{icon-selector-element}.@{icon-base-selector-class}.blackboard:before {
  content: "\e66e";
}
@{icon-selector-element}.@{icon-base-selector-class}.block:before {
  content: "\e66f";
}
@{icon-selector-element}.@{icon-base-selector-class}.book:before {
  content: "\e670";
}
@{icon-selector-element}.@{icon-base-selector-class}.bowl:before {
  content: "\e671";
}
@{icon-selector-element}.@{icon-base-selector-class}.box:before {
  content: "\e672";
}
@{icon-selector-element}.@{icon-base-selector-class}.briefcase:before {
  content: "\e673";
}
@{icon-selector-element}.@{icon-base-selector-class}.browser:before {
  content: "\e674";
}
@{icon-selector-element}.@{icon-base-selector-class}.brush:before {
  content: "\e675";
}
@{icon-selector-element}.@{icon-base-selector-class}.bucket:before {
  content: "\e676";
}
@{icon-selector-element}.@{icon-base-selector-class}.cake:before {
  content: "\e677";
}
@{icon-selector-element}.@{icon-base-selector-class}.calculator:before {
  content: "\e678";
}
@{icon-selector-element}.@{icon-base-selector-class}.calendar:before {
  content: "\e679";
}
@{icon-selector-element}.@{icon-base-selector-class}.camera:before {
  content: "\e67a";
}
@{icon-selector-element}.@{icon-base-selector-class}.ccw:before {
  content: "\e67b";
}
@{icon-selector-element}.@{icon-base-selector-class}.chat:before {
  content: "\e67c";
}
@{icon-selector-element}.@{icon-base-selector-class}.check:before {
  content: "\e67d";
}
@{icon-selector-element}.@{icon-base-selector-class}.circle-with-cross:before {
  content: "\e67e";
}
@{icon-selector-element}.@{icon-base-selector-class}.circle-with-minus:before {
  content: "\e67f";
}
@{icon-selector-element}.@{icon-base-selector-class}.circle-with-plus:before {
  content: "\e680";
}
@{icon-selector-element}.@{icon-base-selector-class}.circle:before {
  content: "\e681";
}
@{icon-selector-element}.@{icon-base-selector-class}.circular-graph:before {
  content: "\e682";
}
@{icon-selector-element}.@{icon-base-selector-class}.clapperboard:before {
  content: "\e683";
}
@{icon-selector-element}.@{icon-base-selector-class}.clipboard:before {
  content: "\e684";
}
@{icon-selector-element}.@{icon-base-selector-class}.clock:before {
  content: "\e685";
}
@{icon-selector-element}.@{icon-base-selector-class}.code:before {
  content: "\e686";
}
@{icon-selector-element}.@{icon-base-selector-class}.cog:before {
  content: "\e687";
}
@{icon-selector-element}.@{icon-base-selector-class}.colours:before {
  content: "\e688";
}
@{icon-selector-element}.@{icon-base-selector-class}.compass:before {
  content: "\e689";
}
@{icon-selector-element}.@{icon-base-selector-class}.copy:before {
  content: "\e68a";
}
@{icon-selector-element}.@{icon-base-selector-class}.credit-card:before {
  content: "\e68b";
}
@{icon-selector-element}.@{icon-base-selector-class}.credit:before {
  content: "\e68c";
}
@{icon-selector-element}.@{icon-base-selector-class}.cross:before {
  content: "\e68d";
}
@{icon-selector-element}.@{icon-base-selector-class}.cup:before {
  content: "\e68e";
}
@{icon-selector-element}.@{icon-base-selector-class}.cw:before {
  content: "\e68f";
}
@{icon-selector-element}.@{icon-base-selector-class}.cycle:before {
  content: "\e690";
}
@{icon-selector-element}.@{icon-base-selector-class}.database:before {
  content: "\e691";
}
@{icon-selector-element}.@{icon-base-selector-class}.dial-pad:before {
  content: "\e692";
}
@{icon-selector-element}.@{icon-base-selector-class}.direction:before {
  content: "\e693";
}
@{icon-selector-element}.@{icon-base-selector-class}.document:before {
  content: "\e694";
}
@{icon-selector-element}.@{icon-base-selector-class}.documents:before {
  content: "\e695";
}
@{icon-selector-element}.@{icon-base-selector-class}.drink:before {
  content: "\e696";
}
@{icon-selector-element}.@{icon-base-selector-class}.drive:before {
  content: "\e697";
}
@{icon-selector-element}.@{icon-base-selector-class}.drop:before {
  content: "\e698";
}
@{icon-selector-element}.@{icon-base-selector-class}.edit:before {
  content: "\e699";
}
@{icon-selector-element}.@{icon-base-selector-class}.email:before {
  content: "\e69a";
}
@{icon-selector-element}.@{icon-base-selector-class}.emoji-happy:before {
  content: "\e69b";
}
@{icon-selector-element}.@{icon-base-selector-class}.emoji-neutral:before {
  content: "\e69c";
}
@{icon-selector-element}.@{icon-base-selector-class}.emoji-sad:before {
  content: "\e69d";
}
@{icon-selector-element}.@{icon-base-selector-class}.erase:before {
  content: "\e69e";
}
@{icon-selector-element}.@{icon-base-selector-class}.eraser:before {
  content: "\e69f";
}
@{icon-selector-element}.@{icon-base-selector-class}.export:before {
  content: "\e6a0";
}
@{icon-selector-element}.@{icon-base-selector-class}.eye:before {
  content: "\e6a1";
}
@{icon-selector-element}.@{icon-base-selector-class}.feather:before {
  content: "\e6a2";
}
@{icon-selector-element}.@{icon-base-selector-class}.flag:before {
  content: "\e6a3";
}
@{icon-selector-element}.@{icon-base-selector-class}.flash:before {
  content: "\e6a4";
}
@{icon-selector-element}.@{icon-base-selector-class}.flashlight:before {
  content: "\e6a5";
}
@{icon-selector-element}.@{icon-base-selector-class}.flat-brush:before {
  content: "\e6a6";
}
@{icon-selector-element}.@{icon-base-selector-class}.folder-images:before {
  content: "\e6a7";
}
@{icon-selector-element}.@{icon-base-selector-class}.folder-music:before {
  content: "\e6a8";
}
@{icon-selector-element}.@{icon-base-selector-class}.folder-video:before {
  content: "\e6a9";
}
@{icon-selector-element}.@{icon-base-selector-class}.folder:before {
  content: "\e6aa";
}
@{icon-selector-element}.@{icon-base-selector-class}.forward:before {
  content: "\e6ab";
}
@{icon-selector-element}.@{icon-base-selector-class}.funnel:before {
  content: "\e6ac";
}
@{icon-selector-element}.@{icon-base-selector-class}.game-controller:before {
  content: "\e6ad";
}
@{icon-selector-element}.@{icon-base-selector-class}.gauge:before {
  content: "\e6ae";
}
@{icon-selector-element}.@{icon-base-selector-class}.globe:before {
  content: "\e6af";
}
@{icon-selector-element}.@{icon-base-selector-class}.graduation-cap:before {
  content: "\e6b0";
}
@{icon-selector-element}.@{icon-base-selector-class}.grid:before {
  content: "\e6b1";
}
@{icon-selector-element}.@{icon-base-selector-class}.hair-cross:before {
  content: "\e6b2";
}
@{icon-selector-element}.@{icon-base-selector-class}.hand:before {
  content: "\e6b3";
}
@{icon-selector-element}.@{icon-base-selector-class}.heart-outlined:before {
  content: "\e6b4";
}
@{icon-selector-element}.@{icon-base-selector-class}.heart:before {
  content: "\e6b5";
}
@{icon-selector-element}.@{icon-base-selector-class}.help-with-circle:before {
  content: "\e6b6";
}
@{icon-selector-element}.@{icon-base-selector-class}.help:before {
  content: "\e6b7";
}
@{icon-selector-element}.@{icon-base-selector-class}.home:before {
  content: "\e6b8";
}
@{icon-selector-element}.@{icon-base-selector-class}.hour-glass:before {
  content: "\e6b9";
}
@{icon-selector-element}.@{icon-base-selector-class}.image-inverted:before {
  content: "\e6ba";
}
@{icon-selector-element}.@{icon-base-selector-class}.image:before {
  content: "\e6bb";
}
@{icon-selector-element}.@{icon-base-selector-class}.images:before {
  content: "\e6bc";
}
@{icon-selector-element}.@{icon-base-selector-class}.inbox:before {
  content: "\e6bd";
}
@{icon-selector-element}.@{icon-base-selector-class}.infinity:before {
  content: "\e6be";
}
@{icon-selector-element}.@{icon-base-selector-class}.info-with-circle:before {
  content: "\e6bf";
}
@{icon-selector-element}.@{icon-base-selector-class}.info:before {
  content: "\e6c0";
}
@{icon-selector-element}.@{icon-base-selector-class}.key:before {
  content: "\e6c1";
}
@{icon-selector-element}.@{icon-base-selector-class}.keyboard:before {
  content: "\e6c2";
}
@{icon-selector-element}.@{icon-base-selector-class}.lab-flask:before {
  content: "\e6c3";
}
@{icon-selector-element}.@{icon-base-selector-class}.landline:before {
  content: "\e6c4";
}
@{icon-selector-element}.@{icon-base-selector-class}.language:before {
  content: "\e6c5";
}
@{icon-selector-element}.@{icon-base-selector-class}.laptop:before {
  content: "\e6c6";
}
@{icon-selector-element}.@{icon-base-selector-class}.leaf:before {
  content: "\e6c7";
}
@{icon-selector-element}.@{icon-base-selector-class}.level-down:before {
  content: "\e6c8";
}
@{icon-selector-element}.@{icon-base-selector-class}.level-up:before {
  content: "\e6c9";
}
@{icon-selector-element}.@{icon-base-selector-class}.lifebuoy:before {
  content: "\e6ca";
}
@{icon-selector-element}.@{icon-base-selector-class}.light-bulb:before {
  content: "\e6cb";
}
@{icon-selector-element}.@{icon-base-selector-class}.light-down:before {
  content: "\e6cc";
}
@{icon-selector-element}.@{icon-base-selector-class}.light-up:before {
  content: "\e6cd";
}
@{icon-selector-element}.@{icon-base-selector-class}.line-graph:before {
  content: "\e6ce";
}
@{icon-selector-element}.@{icon-base-selector-class}.link:before {
  content: "\e6cf";
}
@{icon-selector-element}.@{icon-base-selector-class}.list:before {
  content: "\e6d0";
}
@{icon-selector-element}.@{icon-base-selector-class}.location-pin:before {
  content: "\e6d1";
}
@{icon-selector-element}.@{icon-base-selector-class}.location:before {
  content: "\e6d2";
}
@{icon-selector-element}.@{icon-base-selector-class}.lock-open:before {
  content: "\e6d3";
}
@{icon-selector-element}.@{icon-base-selector-class}.lock:before {
  content: "\e6d4";
}
@{icon-selector-element}.@{icon-base-selector-class}.log-out:before {
  content: "\e6d5";
}
@{icon-selector-element}.@{icon-base-selector-class}.login:before {
  content: "\e6d6";
}
@{icon-selector-element}.@{icon-base-selector-class}.loop:before {
  content: "\e6d7";
}
@{icon-selector-element}.@{icon-base-selector-class}.magnet:before {
  content: "\e6d8";
}
@{icon-selector-element}.@{icon-base-selector-class}.magnifying-glass:before {
  content: "\e6d9";
}
@{icon-selector-element}.@{icon-base-selector-class}.mail:before {
  content: "\e6da";
}
@{icon-selector-element}.@{icon-base-selector-class}.man:before {
  content: "\e6db";
}
@{icon-selector-element}.@{icon-base-selector-class}.map:before {
  content: "\e6dc";
}
@{icon-selector-element}.@{icon-base-selector-class}.mask:before {
  content: "\e6dd";
}
@{icon-selector-element}.@{icon-base-selector-class}.medal:before {
  content: "\e6de";
}
@{icon-selector-element}.@{icon-base-selector-class}.megaphone:before {
  content: "\e6df";
}
@{icon-selector-element}.@{icon-base-selector-class}.menu:before {
  content: "\e6e0";
}
@{icon-selector-element}.@{icon-base-selector-class}.message:before {
  content: "\e6e1";
}
@{icon-selector-element}.@{icon-base-selector-class}.mic:before {
  content: "\e6e2";
}
@{icon-selector-element}.@{icon-base-selector-class}.minus:before {
  content: "\e6e3";
}
@{icon-selector-element}.@{icon-base-selector-class}.mobile:before {
  content: "\e6e4";
}
@{icon-selector-element}.@{icon-base-selector-class}.modern-mic:before {
  content: "\e6e5";
}
@{icon-selector-element}.@{icon-base-selector-class}.moon:before {
  content: "\e6e6";
}
@{icon-selector-element}.@{icon-base-selector-class}.mouse:before {
  content: "\e6e7";
}
@{icon-selector-element}.@{icon-base-selector-class}.music:before {
  content: "\e6e8";
}
@{icon-selector-element}.@{icon-base-selector-class}.network:before {
  content: "\e6e9";
}
@{icon-selector-element}.@{icon-base-selector-class}.new-message:before {
  content: "\e6ea";
}
@{icon-selector-element}.@{icon-base-selector-class}.new:before {
  content: "\e6eb";
}
@{icon-selector-element}.@{icon-base-selector-class}.news:before {
  content: "\e6ec";
}
@{icon-selector-element}.@{icon-base-selector-class}.note:before {
  content: "\e6ed";
}
@{icon-selector-element}.@{icon-base-selector-class}.notification:before {
  content: "\e6ee";
}
@{icon-selector-element}.@{icon-base-selector-class}.old-mobile:before {
  content: "\e6ef";
}
@{icon-selector-element}.@{icon-base-selector-class}.old-phone:before {
  content: "\e6f0";
}
@{icon-selector-element}.@{icon-base-selector-class}.palette:before {
  content: "\e6f1";
}
@{icon-selector-element}.@{icon-base-selector-class}.paper-plane:before {
  content: "\e6f2";
}
@{icon-selector-element}.@{icon-base-selector-class}.pencil:before {
  content: "\e6f3";
}
@{icon-selector-element}.@{icon-base-selector-class}.phone:before {
  content: "\e6f4";
}
@{icon-selector-element}.@{icon-base-selector-class}.pie-chart:before {
  content: "\e6f5";
}
@{icon-selector-element}.@{icon-base-selector-class}.pin:before {
  content: "\e6f6";
}
@{icon-selector-element}.@{icon-base-selector-class}.plus:before {
  content: "\e6f7";
}
@{icon-selector-element}.@{icon-base-selector-class}.popup:before {
  content: "\e6f8";
}
@{icon-selector-element}.@{icon-base-selector-class}.power-plug:before {
  content: "\e6f9";
}
@{icon-selector-element}.@{icon-base-selector-class}.price-ribbon:before {
  content: "\e6fa";
}
@{icon-selector-element}.@{icon-base-selector-class}.price-tag:before {
  content: "\e6fb";
}
@{icon-selector-element}.@{icon-base-selector-class}.print:before {
  content: "\e6fc";
}
@{icon-selector-element}.@{icon-base-selector-class}.progress-empty:before {
  content: "\e6fd";
}
@{icon-selector-element}.@{icon-base-selector-class}.progress-full:before {
  content: "\e6fe";
}
@{icon-selector-element}.@{icon-base-selector-class}.progress-one:before {
  content: "\e6ff";
}
@{icon-selector-element}.@{icon-base-selector-class}.progress-two:before {
  content: "\e700";
}
@{icon-selector-element}.@{icon-base-selector-class}.publish:before {
  content: "\e701";
}
@{icon-selector-element}.@{icon-base-selector-class}.quote:before {
  content: "\e702";
}
@{icon-selector-element}.@{icon-base-selector-class}.radio:before {
  content: "\e703";
}
@{icon-selector-element}.@{icon-base-selector-class}.reply-all:before {
  content: "\e704";
}
@{icon-selector-element}.@{icon-base-selector-class}.reply:before {
  content: "\e705";
}
@{icon-selector-element}.@{icon-base-selector-class}.retweet:before {
  content: "\e706";
}
@{icon-selector-element}.@{icon-base-selector-class}.rocket:before {
  content: "\e707";
}
@{icon-selector-element}.@{icon-base-selector-class}.round-brush:before {
  content: "\e708";
}
@{icon-selector-element}.@{icon-base-selector-class}.rss:before {
  content: "\e709";
}
@{icon-selector-element}.@{icon-base-selector-class}.ruler:before {
  content: "\e70a";
}
@{icon-selector-element}.@{icon-base-selector-class}.scissors:before {
  content: "\e70b";
}
@{icon-selector-element}.@{icon-base-selector-class}.share-alternitive:before {
  content: "\e70c";
}
@{icon-selector-element}.@{icon-base-selector-class}.share:before {
  content: "\e70d";
}
@{icon-selector-element}.@{icon-base-selector-class}.shareable:before {
  content: "\e70e";
}
@{icon-selector-element}.@{icon-base-selector-class}.shield:before {
  content: "\e70f";
}
@{icon-selector-element}.@{icon-base-selector-class}.shop:before {
  content: "\e710";
}
@{icon-selector-element}.@{icon-base-selector-class}.shopping-bag:before {
  content: "\e711";
}
@{icon-selector-element}.@{icon-base-selector-class}.shopping-basket:before {
  content: "\e712";
}
@{icon-selector-element}.@{icon-base-selector-class}.shopping-cart:before {
  content: "\e713";
}
@{icon-selector-element}.@{icon-base-selector-class}.shuffle:before {
  content: "\e714";
}
@{icon-selector-element}.@{icon-base-selector-class}.signal:before {
  content: "\e715";
}
@{icon-selector-element}.@{icon-base-selector-class}.sound-mix:before {
  content: "\e716";
}
@{icon-selector-element}.@{icon-base-selector-class}.sports-club:before {
  content: "\e717";
}
@{icon-selector-element}.@{icon-base-selector-class}.spreadsheet:before {
  content: "\e718";
}
@{icon-selector-element}.@{icon-base-selector-class}.squared-cross:before {
  content: "\e719";
}
@{icon-selector-element}.@{icon-base-selector-class}.squared-minus:before {
  content: "\e71a";
}
@{icon-selector-element}.@{icon-base-selector-class}.squared-plus:before {
  content: "\e71b";
}
@{icon-selector-element}.@{icon-base-selector-class}.star-outlined:before {
  content: "\e71c";
}
@{icon-selector-element}.@{icon-base-selector-class}.star:before {
  content: "\e71d";
}
@{icon-selector-element}.@{icon-base-selector-class}.stopwatch:before {
  content: "\e71e";
}
@{icon-selector-element}.@{icon-base-selector-class}.suitcase:before {
  content: "\e71f";
}
@{icon-selector-element}.@{icon-base-selector-class}.swap:before {
  content: "\e720";
}
@{icon-selector-element}.@{icon-base-selector-class}.sweden:before {
  content: "\e721";
}
@{icon-selector-element}.@{icon-base-selector-class}.switch:before {
  content: "\e722";
}
@{icon-selector-element}.@{icon-base-selector-class}.tablet:before {
  content: "\e723";
}
@{icon-selector-element}.@{icon-base-selector-class}.tag:before {
  content: "\e724";
}
@{icon-selector-element}.@{icon-base-selector-class}.text-document-inverted:before {
  content: "\e725";
}
@{icon-selector-element}.@{icon-base-selector-class}.text-document:before {
  content: "\e726";
}
@{icon-selector-element}.@{icon-base-selector-class}.text:before {
  content: "\e727";
}
@{icon-selector-element}.@{icon-base-selector-class}.thermometer:before {
  content: "\e728";
}
@{icon-selector-element}.@{icon-base-selector-class}.thumbs-down:before {
  content: "\e729";
}
@{icon-selector-element}.@{icon-base-selector-class}.thumbs-up:before {
  content: "\e72a";
}
@{icon-selector-element}.@{icon-base-selector-class}.thunder-cloud:before {
  content: "\e72b";
}
@{icon-selector-element}.@{icon-base-selector-class}.ticket:before {
  content: "\e72c";
}
@{icon-selector-element}.@{icon-base-selector-class}.time-slot:before {
  content: "\e72d";
}
@{icon-selector-element}.@{icon-base-selector-class}.tools:before {
  content: "\e72e";
}
@{icon-selector-element}.@{icon-base-selector-class}.traffic-cone:before {
  content: "\e72f";
}
@{icon-selector-element}.@{icon-base-selector-class}.tree:before {
  content: "\e730";
}
@{icon-selector-element}.@{icon-base-selector-class}.trophy:before {
  content: "\e731";
}
@{icon-selector-element}.@{icon-base-selector-class}.tv:before {
  content: "\e732";
}
@{icon-selector-element}.@{icon-base-selector-class}.typing:before {
  content: "\e733";
}
@{icon-selector-element}.@{icon-base-selector-class}.unread:before {
  content: "\e734";
}
@{icon-selector-element}.@{icon-base-selector-class}.untag:before {
  content: "\e735";
}
@{icon-selector-element}.@{icon-base-selector-class}.user:before {
  content: "\e736";
}
@{icon-selector-element}.@{icon-base-selector-class}.users:before {
  content: "\e737";
}
@{icon-selector-element}.@{icon-base-selector-class}.v-card:before {
  content: "\e738";
}
@{icon-selector-element}.@{icon-base-selector-class}.video:before {
  content: "\e739";
}
@{icon-selector-element}.@{icon-base-selector-class}.vinyl:before {
  content: "\e73a";
}
@{icon-selector-element}.@{icon-base-selector-class}.water:before {
  content: "\e73d";
}
