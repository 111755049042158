// --------------------------------------------------
// Grid mixins
// --------------------------------------------------

// Based on pocketgrid
// @see http://stackoverflow.com/questions/23018741/create-a-css-less-mixin-for-responsive-grid-with-pocket-grid
.grid-wrapper(@max-width) {
  width: 100%;
  max-width: @max-width;
}

.grid-block-group() {
  // ul/li compatibility
  list-style-type: none;
  padding: 0;
  margin: -@grid-block-vertical-gutter 0 0 -@grid-block-horizontal-gutter;
  .clearfix();
  &:after {
    clear: both;
  }
  // Nested grid
  & > & {
    clear: none;
    float: left;
    margin: 0 !important;
  }
}

.grid-block() {
  float: left;
  min-height: 1px;  // Prevent columns from collapsing when empty
  padding: @grid-block-vertical-gutter 0 0 @grid-block-horizontal-gutter;
}

// Default block
.grid-block(@number) {
  .grid-block();
  width: percentage((@number / @grid-number-blocks));
}

.grid-block-offset(@column) {
  margin-left: percentage((@column / @grid-number-blocks));
}

.grid-generate-blocks(@selector, @n: @grid-number-blocks, @i: 1) when (@i =< @n) {
  @cssSelector: ~"@{selector}-@{i}";
  .@{cssSelector} {
    width: percentage((@i / @n));
  }
  .grid-generate-blocks(@selector, @n, (@i + 1));
}
