a.tag {

  &.obor {
    border-left: 0.8em solid transparent;
  }

  &.ortopedie {
    border-color: #2d70f3;
  }

  &.revmatologie {
    border-color: #ff0000;
  }

  &.prakticky-lekar-pro-dospele {
    border-color: #ffd500;
  }

  &.lecebna-rehabilitace-fyziatrie,
  &.masaze-lecebny-telocvik-a-rekondicni-aktivity {
    border-color: #00b000;
  }

}
