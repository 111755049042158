// Icon aliases, so we can change the icon without the need to modify HTML element

// TODO: when extend allows to extend dynamic class and use variable, implement as a mixin!
// https://github.com/less/less.js/issues/2200

// alias for chevron-down
@{icon-selector-element}.@{icon-base-selector-class}.dropdown:before {
  content: "\e621";
}

// alias for cross
@{icon-selector-element}.@{icon-base-selector-class}.close:before {
  content: "\e68d";
}

// alias for circle-with-cross
@{icon-selector-element}.@{icon-base-selector-class}.cancel:before {
  content: "\e67e";
}

// alias for lab-flask
@{icon-selector-element}.@{icon-base-selector-class}.filter:before {
  content: "\e6c3";
}

// alias for retweet
@{icon-selector-element}.@{icon-base-selector-class}.repeat:before {
  content: "\e706";
}

// alias for chevron-right
@{icon-selector-element}.@{icon-base-selector-class}.next:before {
  content: "\e623";
}

// alias for chevron-left
@{icon-selector-element}.@{icon-base-selector-class}.previous:before {
  content: "\e622";
}

// alias for circular-graph
@{icon-selector-element}.@{icon-base-selector-class}.refresh:before {
  content: "\e682";
}
