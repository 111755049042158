// --------------------------------------------------
// Images and embedded content
// --------------------------------------------------

@import '../core/core';
@import './_variables';
@import '../../mixins/_images';

// Fixes for Google maps images
img[src*="gstatic.com/"],
img[src*="googleapis.com/"] {
  max-width: none;
}

img {
  //  1. Remove the gap between <img> elements and the bottom of their containers
  vertical-align: middle; // 1.
  -ms-interpolation-mode: bicubic;
  border: 0;

  &.right {
    float: right;
    margin: 0 0 0.5em 0.5em;
  }

  &.left {
    float: left;
    margin: 0 0.5rem 0.5em 0;
  }
}

// Fluid-width <audio> and <video> elements
audio,
video {
  width: 100%;
  &:focus {
    outline: @embedded-content-focus-outline;
  }
}

// Fluid-width media that maintain their aspect ratios
embed,
img,
object,
video {
  max-width: 100%;
  height: auto;
}
