@taxonomy-tag-padding: 0.4em 0.7em 0.4em 1.5em;
@taxonomy-tag-color: #000;
@taxonomy-tag-border-radius: 1em 0.2em 0.2em 1em;
@taxonomy-tag-background: #ddd;
@taxonomy-tag-hover-background: #ccc;
@taxonomy-tag-hover-color: #222;

// dot
@taxonomy-tag-dot-width: 0.5em;
@taxonomy-tag-dot-height: 0.5em;
@taxonomy-tag-dot-color: #fff;
@taxonomy-tag-dot-border-radius: 4.167em;

// compact version
@taxonomy-tag-compact-padding: 0.2em 0.3em 0.2em 0.3em;
@taxonomy-tag-compact-border-radius: 0.5em 0.2em 0.2em 0.5em;
